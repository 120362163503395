
import { IonSegment, IonSegmentButton, IonIcon } from '@ionic/vue';
import { calendarOutline, locationOutline } from 'ionicons/icons';
import { computed, onMounted, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import useDeskBooking from '@/composables/useDeskBooking';
import Calendar from '@/components/Calendar.vue';
import { zoomable } from '@/utils/image.utils';
import useNavigation from '@/composables/useNavigation';
import { toast } from '@/utils/frameworkFeatures';
import { getEventTime } from '@/utils/date.utils';
import dayjs from 'dayjs';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';

export default {
    name: 'DeskBookingDetails',
    components: {
        Calendar,
        IonSegment, IonSegmentButton,
        IonIcon
    },
    setup() {
        const route = useRoute();
        const store = useMainStore();
        const { gotoDeskBooking, goHome } = useNavigation();
        const { 
            bookDesk,
            cancelReservation,
            deskFiltersModel,
            deskSpaceEvents,
            editReservation,
            editingEvent,
            initialEndTime,
            initialStartTime,
            isAvailable,
            isValidTimeSlot,
            onFiltersTimeUpdate,
            selectedDeskSpace,
            setDeskSpace,
            timeGapText,
            syncDeskEvents,
        } = useDeskBooking();

        const { customer } = storeToRefs(store);

        const showCalendar = ref<boolean>(false);
        const imageMap = ref(null);
        const selectionType = ref('schedule');

        provide('appTitle', 'Desk booking');

        onMounted(() => {
            syncDeskEvents();

            // TODO: deskBookingBetaV2 feature flag
            if(editingEvent.value && !store.hasdeskBookingBetaV2) {
                goHome();
            }
        });

        function setDeskData() {
            setDeskSpace(); // sets selectedDeskSpace
            if (!selectedDeskSpace.value) {
                toast({ message: 'Desk not found.' });
                return gotoDeskBooking();
            } else {
                provide('appTitle', selectedDeskSpace.value.turf_meta.space_name);
            }
        }

        watch(imageMap, (value: any) => {
            if (value) {
                zoomable(value);
            }
        });

        // need this when scanning desk QR
        watch([() => route.name, customer], ([cur, customer]: any, prev: any) => {
            if (customer && cur && cur === 'DeskBookingDetails' && cur !== prev) {
                setDeskData();
            }
        }, { immediate: true });

        watch(() => route.name, (cur: any, prev: any) => {
            if (customer.value && cur && cur === 'EditReservation' && cur !== prev) {
                setDeskData();
            }
        }, { immediate: true });

        const spaceEvents = computed(() => {
            return deskSpaceEvents.value.get(selectedDeskSpace.value.id) || [];
        });

        const onSegmentChanged = (evt: CustomEvent) => {
            const el = evt.target as HTMLInputElement;
            selectionType.value = el.value;
        };

        // indicate if the user changed the time, when editing a reservation.
        const editingPristine = computed(() => {
            if (editingEvent.value) {
                const { start, end } = getEventTime(editingEvent.value);
                const currentStartValue = dayjs(start).format('Hmm');
                const currentEndValue = dayjs(end).format('Hmm');
                const newStartValue = dayjs( deskFiltersModel.value.startTime).format('Hmm');
                const newEndValue = dayjs( deskFiltersModel.value.endTime).format('Hmm');

                return currentStartValue === newStartValue && currentEndValue === newEndValue;
            } else {
                return false;
            }
        });

        return {
            bookDesk,
            calendarOutline,
            cancelReservation,
            deskFiltersModel,
            editReservation,
            editingEvent,
            editingPristine,
            imageMap,
            initialEndTime,
            initialStartTime,
            isAvailable,
            isValidTimeSlot,
            locationOutline,
            onFiltersTimeUpdate,
            onSegmentChanged,
            selectedDeskSpace,
            selectionType,
            showCalendar,
            spaceEvents,
            timeGapText,
            store,
        };
    },
};
