
import { defineComponent, ref, watch } from 'vue';
import dayjs from 'dayjs';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import useDeskBooking from '@/composables/useDeskBooking';

export default defineComponent({
    name: 'Calendar',
    components: { VueCal },
    props: {
        events: Object
    },
    setup(props) {
        const {
            prepareCalendarData
        } = useDeskBooking();

        const preparedEvents = ref([]);

        watch(props, (e: any) => {
            if (e) {
                preparedEvents.value = prepareCalendarData(e?.events);
            }
        }, {
            immediate: true
        });

        const onEventClick = () => {
            console.log('clicked');
        };

        const startCurrentHour = (dayjs().hour()) * 60;


        return {
            dayjs,
            onEventClick,
            startCurrentHour,
            preparedEvents
        };
    },
});
