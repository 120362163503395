import Hammer from 'hammerjs';

export function zoomable(el: any) {
    const hammer = new Hammer(el, {});
    hammer.get('pinch').set({ enable: true, });

    let posX = 0;
    let posY = 0;
    let scale = 1;
    let lastScale = 1;
    let lastPosX = 0;
    let lastPosY = 0;
    let maxPosx = 0;
    let maxPosy = 0;
    let transform = '';

    hammer.on('doubletap pan pinch panend pinchend', function (event: any) {
        if (event.type == 'doubletap') {
            transform = 'translate3d(0, 0, 0) ' + 'scale3d(2, 2, 1) ';
            scale = 2;
            lastScale = 2;
            try {
                if (
                    window
                        .getComputedStyle(el, null)
                        .getPropertyValue('-webkit-transform')
                        .toString() != 'matrix(1, 0, 0, 1, 0, 0)'
                ) {
                    transform = 'translate3d(0, 0, 0) scale3d(1, 1, 1) ';
                    scale = 1;
                    lastScale = 1;
                }
            } catch (err) {/* */ }
            el.style.webkitTransform = transform;
            transform = '';
        }

        //pan
        if (scale != 1) {
            posX = lastPosX + event.deltaX;
            posY = lastPosY + event.deltaY;
            maxPosx = Math.ceil(((scale - 1) * el.clientWidth) / 2);
            maxPosy = Math.ceil(((scale - 1) * el.clientHeight) / 2);
            if (posX > maxPosx) {
                posX = maxPosx;
            }
            if (posX < -maxPosx) {
                posX = -maxPosx;
            }
            if (posY > maxPosy) {
                posY = maxPosy;
            }
            if (posY < -maxPosy) {
                posY = -maxPosy;
            }
        }

        //pinch
        if (event.type == 'pinch') {
            scale = Math.max(0.999, Math.min(lastScale * event.scale, 4));
        }
        if (event.type == 'pinchend') {
            lastScale = scale;
        }

        if (event.type == 'panend') {
            lastPosX = posX < maxPosx ? posX : maxPosx;
            lastPosY = posY < maxPosy ? posY : maxPosy;
        }

        if (scale != 1) {
            transform = `translate3d(${posX}px,${posY}px, 0) scale3d(${scale}, ${scale}, 1)`;
        }

        if (transform) {
            el.style.webkitTransform = transform;
        }
    });
}
