import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_cal = _resolveComponent("vue-cal")!

  return (_openBlock(), _createBlock(_component_vue_cal, {
    id: "_vue_cal",
    ref: "vueCalRef",
    "time-from": _ctx.startCurrentHour,
    events: _ctx.preparedEvents,
    "time-step": 30,
    "time-cell-height": 80,
    "twelve-hour": true,
    "active-view": "day",
    "on-event-click": _ctx.onEventClick,
    "hide-view-selector": "",
    "hide-title-bar": "",
    "default-view": "day",
    "disable-views": ['years', 'year', 'month']
  }, {
    event: _withCtx(({ event }) => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(event.title), 1)
    ]),
    _: 1
  }, 8, ["time-from", "events", "on-event-click"]))
}